SymposiumConfigCtrl = ($scope, $http) ->
  $scope.initializeRules = (mapping_rules) ->
    if mapping_rules
      $scope.rules = mapping_rules
    else
      $scope.rules = []

  $scope.getUsers = (rule) ->
    $http.get("/search/users?q=#{encodeURIComponent(rule.user_email)}")
      .then((response) =>
        response.data
      )

  $scope.getSites = (rule) ->
    $http.get("/search/sites?q=#{encodeURIComponent(rule.destination_user)}")
      .then((response) =>
        rule.available_sites = response.data
      )

  $scope.getJourneys = (rule) ->
    $http.get("/search/journeys")
      .then((response) =>
        rule.available_journeys = response.data
      )

  $scope.handleSelect = (event, rule) ->
    rule.user_email = event.email
    rule.destination_user = event.id

    $scope.getSites(rule)
  
  $scope.clearDependentValues = (rule) ->
    rule.available_sites = null
    rule.destination_user = null

angular
  .module('mmm.symposium-config', ["ui.bootstrap.typeahead"])
  .controller('MmmSymposiumConfigCtrl', ['$scope', '$http', SymposiumConfigCtrl])
