autoFillSyncDirective = ($timeout) ->
  {
    require: 'ngModel',
    link: (scope, elem, attrs, ngModel) ->
      $timeout ->
        val = elem.val()
        if ngModel.$pristine and val
          ngModel.$setViewValue val
      , 500
  }

angular
  .module("mmm.auto-complete", [])
  .directive("autoCompleteSync", ["$timeout", autoFillSyncDirective])

