require("./navigation");

ng = angular

class BaseModalInstCtrl
  constructor: (@$scope, @$uibModalInstance) ->
    @$scope.close = ng.bind @$uibModalInstance, @$uibModalInstance.close
    @$scope.dismiss = ng.bind @$uibModalInstance, @$uibModalInstance.dismiss

class LogoutModalInstCtrl extends BaseModalInstCtrl
  constructor: (@$scope, $uibModalInstance, $http) ->
    super
    @$scope.logout = -> window.location = '/users/auto_sign_out?_cp=' + window.location.pathname

modalDirective = ($uibModal, navigate) ->
  urlFor = (url, clearCache) ->
    if clearCache
      url += if url.indexOf('?') >= 0 then '&' else '?'
      url += '_t=' + new Date().getTime()
    url

  link = (scope, element, attrs) ->
    element.on 'click', (e) ->
      e.preventDefault()
      attrs.mmmModal or= 'MmmBaseModalInstCtrl'
      modalInst = $uibModal.open
        controller: attrs.mmmModal
        templateUrl: urlFor(
          attrs.mmmModalUrl
          typeof attrs.mmmModalClearCache isnt 'undefined'
        )
      modalInst.result.then ->
        if attrs.mmmModalReload
          navigate() # refresh
        else if attrs.href and attrs.href isnt '#'
          navigate attrs.href
      , ->    

  restrict: 'AC'
  link: link

confirmFilter = ($uibModal) ->
  ->
    modalInst = $uibModal.open
      controller: 'MmmBaseModalInstCtrl'
      templateUrl: 'confirmation-modal.html'
    modalInst.result

sessionTimeoutNotify = ($rootScope, $uibModal) ->
  ->
    modalInst = $uibModal.open
      controller: 'MmmLogoutModalInstCtrl',
      backdrop : 'static',
      templateUrl: 'session-timeout-notification-modal.html'

    $rootScope.$on 'mmm.session.resetFromCounter', ->
      modalInst.close() if modalInst
      modalInst = undefined

    modalInst.result.then ->
      $rootScope.$broadcast 'mmm.session.reset'

ng
  .module('mmm.modal', ['ui.bootstrap', 'mmm.navigation'])
  .controller('MmmBaseModalInstCtrl', ['$scope', '$uibModalInstance', BaseModalInstCtrl])
  .controller('MmmLogoutModalInstCtrl', ['$scope', '$uibModalInstance', '$http', '$window', LogoutModalInstCtrl])
  .filter('confirm', ['$uibModal', confirmFilter])
  .filter('sessionTimeoutNotify', ['$rootScope', '$uibModal', sessionTimeoutNotify])
  .directive('mmmModal', ['$uibModal', 'navigate', modalDirective])
