SelectAllCtrl = ($scope) ->
  $scope.init = ->
    statuses = if $scope.selectedStatuses.length then $scope.selectedStatuses else $scope.allStatuses
    changeValues(statuses, true)

  $scope.selectAll = ->
    changeValues($scope.allStatuses, true)

  $scope.deselectAll = ->
    changeValues($scope.allStatuses, false)

  changeValues = (statuses, value) ->
    angular.forEach statuses, (item) ->
      $scope[item] = value

angular
  .module('mmm.select-all', [])
  .controller('MmmSelectAllCtrl', ['$scope', SelectAllCtrl])
