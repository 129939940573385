ng = angular

actionListDirective = ->
  link = (scope, element) ->
    $lis = element.find "li"
    $select = ng.element """
      <select class="form-control"><option value="#">Select</option></select>
    """
    $select.on "change", (e) -> window.location = ng.element(@).val()
    ng.forEach $lis, (item) ->
      $item = ng.element item
      $a = ng.element $item.find "a"
      if $a.length > 0
        $option = ng.element "<option></option>"
        $option.val $a.attr "href"
        $option.html $a.html()
        $select.append $option
    element.replaceWith $select
  {
    restrict: "AC"
    link: link
  }

ng
  .module("mmm.action-list", [])
  .directive("actionList", actionListDirective)
