ng = angular

class BaseCtrl
  bind: (method) ->
    if typeof method is "string"
      method = @[method]
    ng.bind this, method

ng.mmm or= {}
ng.mmm.BaseCtrl = BaseCtrl

