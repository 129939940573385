guid = do ->
  s4 = ->
    Math
      .floor (1 + Math.random()) * 0x10000
      .toString 16
      .substring 1
  -> "#{s4()}#{s4()}-#{s4()}-#{s4()}-#{s4()}-#{s4()}#{s4()}#{s4()}"

# This function is added as an alternative to JS URL library as it doesn't support IE11
ParsedUrl = (url) ->
  parser = document.createElement('a')
  parser.href = url
  # IE 8 and 9 dont load the attributes "protocol" and "host" in case the source URL
  # is just a pathname, that is, "/example" and not "http://domain.com/example".
  parser.href = parser.href
  # IE 7 and 6 wont load "protocol" and "host" even with the above workaround,
  # so we take the protocol/host from window.location and place them manually
  if parser.host == ''
    newProtocolAndHost = window.location.protocol + '//' + window.location.host
    if url.charAt(1) == '/'
      parser.href = newProtocolAndHost + url
    else
      # the regex gets everything up to the last "/"
      # /path/takesEverythingUpToAndIncludingTheLastForwardSlash/thisIsIgnored
      # "/" is inserted before because IE takes it of from pathname
      currentFolder = ('/' + parser.pathname).match(/.*\//)[0]
      parser.href = newProtocolAndHost + currentFolder + url
  # copies all the properties to this object
  properties = [
    'host'
    'hostname'
    'hash'
    'href'
    'port'
    'protocol'
    'search'
  ]
  i = 0
  n = properties.length
  while i < n
    @[properties[i]] = parser[properties[i]]
    i++
  # pathname is special because IE takes the "/" of the starting of pathname
  @pathname = (if parser.pathname.charAt(0) != '/' then '/' else '') + parser.pathname
  return

angular
  .module "mmm.loading", ["ipCookie"]

  .directive "mmmLoading", ["$timeout", ($timeout)->
    restrict: "A"
    link: (scope, element, attrs)->
      element.one "click", ->
        icon = element.find "i"
        icon.attr "class", "icon icon-spin icon-cog" if icon.hasClass "icon"
        element.addClass "disabled"
        element.on "click", (e)-> e.preventDefault()
        $timeout -> element.prop "disabled", yes
  ]

  .controller "MmmDownload", [
    "$scope", "ipCookie", "$interval"
    ($scope, ipCookie, $interval) ->
      $scope.checkHiddenButtons = (totalCount, maxDownloadSize)->
        $scope.hideDownloadButton = $scope.checkMaxDownloadLimit(totalCount, maxDownloadSize) || $scope.checkZeroRecord(totalCount)
      $scope.checkZeroRecord = (totalCount) ->
        $scope.showZeroRecordMessage = totalCount == 0
      $scope.checkMaxDownloadLimit = (totalCount, maxDownloadSize) ->
        $scope.showMaxDownloadMessage = totalCount > maxDownloadSize
      $scope.uid = guid()
      $scope.disableMessage = ""
      $scope.downloadDisabled = no
      $scope.emailDisabled = no
      $scope.disableDownload = -> $scope.downloadDisabled = yes
      $scope.disableEmail = -> $scope.emailDisabled = yes
      $scope.startDownload = ($event) ->
        $event.stopPropagation()
        $event.preventDefault()

        return if $scope.downloadDisabled

        $scope.downloadDisabled = yes

        link = $event.currentTarget
        url = new ParsedUrl(link.href)
        # NOTE: It is expected that download button/link have a text as
        #       the last child node. I.e. have DOM structure like below
        #
        #       <a class="btn btn-default" href="/download.xlsx">
        #         <i class="icon icon-download"></i>
        #         Download XLSX
        #       </a>
        #
        #       This matches existing code. And minimize the amount of changes.
        originalmessage = link.childNodes[link.childNodes.length - 1].textContent

        parts = url.pathname.split("/")
        fileName = parts.pop()

        req = new XMLHttpRequest();
        req.open("GET", link.href, true)
        req.responseType = "blob"

        req.onload = (event) ->
          if req.status == 200
            blob = req.response
            contentType = req.getResponseHeader("content-type")

            if window.navigator.msSaveOrOpenBlob
              # NOTE: With IE we have to use this to save blob received.
              window.navigator.msSaveOrOpenBlob(blob, fileName)
            else
              downloadLink = window.document.createElement("a");
              downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentType }))
              downloadLink.download = fileName
              link.parentNode.insertBefore(downloadLink, link)

              downloadLink.click()

              window.URL.revokeObjectURL(downloadLink.href)
              downloadLink.remove()
          else
            blobReader = new FileReader()
            blobReader.addEventListener("loadend", () -> alert(blobReader.result))
            blobReader.readAsText(req.response)

          link.childNodes[2].textContent = originalmessage
          $scope.downloadDisabled = no
          # NOTE: Because state is changing in the callback, we have to ask
          #       Angular to re-check it to update UI based on the new state.
          $scope.$digest()

        req.onprogress = (e) ->
          link.childNodes[2].textContent = " #{Math.round(event.loaded / 1024)}KB..."

        req.send()
  ]
