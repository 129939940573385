ng = angular

urlParamsToHiddenInputs = (params) ->
  (
    for param in params.split '&'
      [key, value] = param.split '='
      ng.element "<input type=\"text\" name=\"#{key}\" value=\"#{value}\"/>"
  )

urlToForm = (url) ->
  form = ng.element "<form action=\"#{url}\" method=\"get\"/>"
  queryIndex = url.indexOf '?'
  if queryIndex >= 0
    path = url.substr 0, queryIndex
    params = url.substr queryIndex + 1
    form.prop 'action', path
    inputs = urlParamsToHiddenInputs params
    form.append input for input in inputs
  form

navigateFactory = ($document, $location) ->
  (url) ->
    url = $location.absUrl() unless url
    form = urlToForm url
    $document.find('body').append form
    form[0].submit()

ng
  .module('mmm.navigation', [])
  .factory('navigate', ['$document', '$location', navigateFactory])

