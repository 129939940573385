ng = angular

class MultipleSelectCtrl extends ng.mmm.BaseCtrl

  sortByLabel = (a, b) ->
    a.label.localeCompare b.label

  constructor: (@scope, @http, @parse, @filter, @q) ->
    @enabled = []
    @scope.init = @bind @init

  getEnabled: ->
    scope = @scope
    if !!scope.enabledPath
      @http.get(scope.enabledPath).then ({data}) ->
        enabled = []
        ng.forEach data, (label, key) -> enabled.push key
        enabled
    else if !!scope.enabledItems
      @q.when JSON.parse scope.enabledItems
    else
      @q.when []

  selectAllOnInit: ->
    @selectAll() if @scope.selectAllOnInit

  getAll: ->
    scope = @scope
    if !!scope.allPath
      @http.get(@scope.allPath).then ({data}) ->
        data
    else if !!scope.all
      @q.when JSON.parse scope.all
    else
      @q.when {}


  bindScope: ->
    scope = @scope
    scope.enable = @bind @enableSelected
    scope.disable = @bind @disableSelected
    scope.enableAll = @bind @selectAll
    scope.disableAll = @bind @dismissAll
    scope.$watch "enabled", @bind @amendDisabled
    scope.$watch "all", @bind @amendEnabled
    scope.$watch "autoSelection", @bind @autoSelectionChanged
    scope.$watch "searchDisabled", @bind @clearSelectedItemsToEnable
    scope.$watch "searchEnabled", @bind @clearSelectedItemsToDisable

  init: ->
    scope = @scope
    scope.enabled = []
    scope.toEnable = []
    scope.toDisable = []
    if scope.parentName && scope.parentScope
      scope.$watch('enabled', (newVal, oldVal) ->
        scope.$parent[scope.parentName][scope.parentScope] = newVal
      )
    @bindScope()
    @getEnabled()
      .then (@enabled) => @getAll()
      .then (@all) =>
        scope.all = @all
        @amendEnabled()
        @amendDisabled()
        @selectAllOnInit()

  clearSelectedItemsToEnable: ->
    @scope.toEnable = []

  clearSelectedItemsToDisable: ->
    @scope.toDisable = []

  amendDisabled: ->
    scope = @scope
    disabled = []
    for own key, label of scope.all
      unless key in @enabled
        disabled.push key: key, label: label
    disabled.sort sortByLabel
    scope.disabled = disabled

  amendAutoSelection: ->
    scope = @scope
    if scope.enabled?.length < 1 and scope.autoSelection is 'default_location'
      scope.autoSelection = 0

  autoSelectionChanged: ->
    scope = @scope
    if scope.autoSelection is 'default_location' and scope.enabled.length
      scope.defaultLocation = scope.enabled[0].key

  amendEnabled: ->
    scope = @scope
    if scope.all
      enabled = ({key: key, label: scope.all[key]} for key in @enabled)
      scope.enabled = enabled
      @amendAutoSelection()

  enableSelected: ->
    scope = @scope
    @enabled = @enabled.concat scope.toEnable
    scope.toEnable = []
    @amendEnabled()

  disableSelected: ->
    scope = @scope
    for key in scope.toDisable
      index = @enabled.indexOf key
      @enabled.splice index, 1 if index >= 0
    scope.toDisable = []
    @amendEnabled()

  selectAll: ->
    scope = @scope
    @enabled = @enabled.concat scope.disabled.map (item) -> item.key
    scope.disabled = []
    @amendEnabled()

  dismissAll: ->
    scope = @scope
    @enabled = []
    @amendEnabled()

ng
  .module("mmm.multiple-select", [])
  .controller("MmmMultipleSelect", ["$scope", "$http", "$parse", "$filter", "$q", MultipleSelectCtrl])
