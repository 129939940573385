window.setNotificationUpdateHandler = ->
  $(".js-update-notification, .js-delete-notification").on "click", (e) ->
    e.preventDefault()
    $form = $(@).closest("form")
    $form.data("notification-delete", $(@).hasClass("js-delete-notification"))
    $form.data("notification-url", $(@).attr("href"))
    $.ajax({
      type: "POST",
      url: $form.attr("action"),
      data: $form.serialize(),
      dataType: "script"
    });

$ ->
  setNotificationUpdateHandler()
