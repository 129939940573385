require("./base-controller");

ng = angular

class AlertsCtrl extends ng.mmm.BaseCtrl
  constructor: (@$scope, @$timeout, @$sce) ->
    @$scope.alerts = []
    @$scope.addAlert = @bind @addAlert
    @$scope.removeAlert = @bind @removeAlert
    @$scope.addAlerts = @bind @addAlerts
    @$scope.$on 'alert', @bind @onAlert
    @$scope.$on 'mute', @bind @onMute
    @duration = 5000
    @timeout = undefined

  addAlerts: (alerts = []) ->
    alerts.forEach @bind (alert) ->
      @addAlert alert


  addAlert: (alert) ->
    return if !alert.msg
    alert.type ?= 'warning'
    alert.msg = @$sce.trustAsHtml(alert.msg)
    @$scope.alerts.push alert
    @$timeout.cancel @timeout
    @timeout = @$timeout ( @bind ->
      @$scope.alerts = [])
    , @duration

  onAlert: (e, alert) ->
    @addAlert alert

  removeAlert: (index) ->
    alerts = @$scope.alerts
    if +index >= 0
      alerts.splice index, 1
    else
      alerts.pop()

  onMute: (event, index) ->
    @removeAlert index

ng
  .module('mmm.alerts', ['ui.bootstrap.alert'])
  .controller('MmmAlertsCtrl', ['$scope', '$timeout', '$sce', AlertsCtrl])

ng
  .module('uib/template/alert/alert.html')
  .run ['$templateCache', ($templateCache) ->
    $templateCache.put "uib/template/alert/alert.html", """
      <span ng-transclude></span>
      <button ng-show="closeable" type="button" class="close" ng-click="close()">
        <i class="icon icon-times"></i>
      </button>
    """
  ]
