moment = require('moment')
require('angular-ui-bootstrap');
require("./base-controller");
I18n = window.I18n
ng = angular

reconciliationHistoryFactory = ($resource) ->
  base = '/contact_centre/reporting/reconciliations/:reportReference'
  $resource "#{base}/reconciliation_history.json", {
    reportReference: '@reportReference'
  }, {
    save:
      url: "#{base}/create_manual_reconciliation.json"
      method: 'POST'
    update:
      url: "#{base}/update_manual_reconciliation.json"
      method: 'POST'
    delete:
      url: "#{base}/remove_manual_reconciliation/:reference.json"
      method: 'GET'
      params:
        reference: '@reference'
  }


reconciliationFactory = ($resource) ->
  base = '/contact_centre/reporting/reconciliations/:reportReference'
  $resource "#{base}/reconciliations.json", {
    reportReference: '@reportReference'
    safety_report_version: '@safety_report_version'
  }, {
    save:
      url: "#{base}/create_manual_reconciliation.json"
      method: 'POST'
    update:
      url: "#{base}/update_manual_reconciliation.json"
      method: 'POST'
    delete:
      url: "#{base}/remove_manual_reconciliation/:reference.json"
      method: 'GET'
      params:
        reference: '@reference'
  }

reportHistoryFactory = ($resource, ReconciliationHistory) ->
  base = '/contact_centre/reporting/reconciliations/:reference'
  ReportHistory = $resource "#{base}.json",
    reference: '@reference'
  ReportHistory::$reconciliations = ->
    params =
      reportReference: @reference
    recons = ReconciliationHistory.query params, =>
      for recon in recons
        recon.reportReference = @reference
  ReportHistory

reportFactory = ($resource, Reconciliation) ->
  base = '/contact_centre/reporting/reconciliations/:reference'
  Report = $resource "#{base}.json",
    reference: '@reference'
    safety_report_version: '@safety_report_version'
  Report::$reconciliations = ->
    params =
      reportReference: @reference
      safety_report_version: @safety_report_version
    recons = Reconciliation.query params, =>
      for recon in recons
        recon.reportReference = @reference
        recon.safety_report_version = @safety_report_version
  Report

systemFactory = ($resource) ->
  $resource '/contact_centre/reporting/reconciliations_system_list.json'

class ContactCentreReconciliationsCtrl extends ng.mmm.BaseCtrl
  constructor: (@Report, @Reconciliation, @ReportHistory, @ReconciliationHistory, System, @confirm, @$scope,
  @$rootElement) ->
    @$scope.reconciliations = []
    @$scope.adding = no
    @$scope.editing = no
    @$scope.systems = System.query()
    @$scope.cantAdd = @bind @cantAdd
    @$scope.showTable = @bind @showTable
    @$scope.add = @bind @add
    @$scope.create = @bind @create
    @$scope.edit = @bind @edit
    @$scope.cancelEditing = @bind @cancelEditing
    @$scope.update = @bind @update
    @$scope.close = @bind @close
    @$scope.del = @bind @del
    @$scope.complete = @bind @complete
    @$scope.cantChangeStatus = @bind @cantChangeStatus
    @$scope.formatDate = @bind @formatDate
    @$scope.maybeDisplaySystem = @bind @maybeDisplaySystem
    @$scope.reference = undefined
    @$scope.promise = null
    @$scope.$watch 'reference', @bind @init
    @$scope.$watch 'status', @bind @statusChanged
    @$scope.$watchCollection 'reconciliations', @bind @reconciliationsChanged
    @$scope.evalTranslation = @bind @evalTranslation

    @reset()

  init: ->
    scope = @$scope
    params =
      reference: scope.reference
      safety_report_version: scope.safety_report_version

    scope.promise = report = @ReportHistory.get params, ->
      scope.reconciliations = report.$reconciliations()
      scope.promise = scope.reconciliations.$promise
      scope.status = report.status
    @report = report


  cantAdd: ->
    scope = @$scope
    scope.adding or scope.editing or @complete()

  showTable: ->
    scope = @$scope
    scope.adding or scope.reconciliations.length

  add: ->
    @$scope.adding = yes
    @$scope.newSystem = ''

  close: ->
    @$scope.adding = no
    @reset()

  create: ->
    scope = @$scope
    report = @report
    recon = new @Reconciliation
      reportReference: report.reference
      safety_report_version: report.safety_report_version
      reference: scope.newReference
      system: scope.newSystem
    scope.promise = recon.$save ->
      recon.reportReference = report.reference
      recon.safety_report_version = report.safety_report_version
      recon.user = recon.user_email
      scope.reconciliations[report.safety_report_version-1].manual_reconciliations.push recon
    @close()

  # We're passing the whole reconciliations object instead of the single one we're updating,
  # because we need a $resource object if we want to edit the object.
  # reconciliations in this case is a $resource.
  edit: (reconciliations, version, key, element_index) ->
    reconciliations = reconciliations[version-1]
    manual_reconciliations = reconciliations.manual_reconciliations
    reconciliations.manual_reconciliations[element_index].previousReference = reconciliations.manual_reconciliations[element_index].reference
    @$scope.editing = reconciliations
    @$scope.editing_key = key
    @$scope.editing_index = element_index

  cancelEditing: ->
    reconciliations = @$scope.editing
    reconciliations.manual_reconciliations[@$scope.editing_index].reference = reconciliations.manual_reconciliations[@$scope.editing_index].previousReference
    @$scope.editing = no

  update: ->
    scope = @$scope
    editing = scope.editing # the reconciliations, which is a $resource
    # create a temporary Reconciliation that will be replacing the one in the db
    recon = new @Reconciliation editing.manual_reconciliations[scope.editing_index]

    scope.promise = recon.$update {
      key: scope.editing_key
      reportReference: scope.reference
      safety_report_version: scope.safety_report_version
    },
      (data)->
        editing.reportReference = scope.reference
        editing.safety_report_version = scope.safety_report_version
        editing.manual_reconciliations[scope.editing_index].user = data.user_email
        scope.editing = no

  del: (reconciliations, version, key, element_index) ->
    scope = @$scope
    @confirm().then ->
      reconciliations[version-1].reference = reconciliations[version-1].manual_reconciliations[element_index].reference
      scope.promise = reconciliations[version-1].$delete
        safety_report_version: version
        key: key
      reconciliations[version-1].manual_reconciliations.splice(element_index, 1)

  reset: ->
    scope = @$scope
    scope.newSystem = scope.systems[0]
    scope.newReference = null

  complete: ->
    @$scope.status is 'complete'

  statusChanged: (status) ->
    scope = @$scope
    report = @report
    reconciliations = scope.reconciliations
    if status is 'complete'
      @cancelEditing() if scope.editing
      @close()
    unless status is report.status
      report.status = status
      scope.promise = report.$save()
    scope.$emit "#{scope.reference} status", status

  reconciliationsChanged: (reconciliations) ->
    scope = @$scope
    scope.$emit "#{scope.reference} reconciliations", reconciliations

  cantChangeStatus: ->
    return true unless @$scope.reconciliations.length
    not @$scope.reconciliations[@$scope.safety_report_version - 1].manual_reconciliations.length

  formatDate: (date) ->
    locale = I18n.locale
    if locale is 'ja'
      format = 'YYYY/MM/DD'
    else
      format = 'DD MMM YYYY'
      monthObj = I18n.t('javascript.calendar.months.short')
      monthMaps = [
        'jan'
        'feb'
        'mar'
        'apr'
        'may'
        'jun'
        'jul'
        'aug'
        'sep'
        'oct'
        'nov'
        'dec'
      ]
      months = monthMaps.map((month) => monthObj[month])
      moment.updateLocale(locale, {monthsShort : months})

    moment(date).locale(locale).utc().format("#{format} HH:mm:ss")

  maybeDisplaySystem: (user) ->
    if user == 'system.user@mymedsandme.com' then 'System' else user

  evalTranslation: (status) ->
    I18n.t("javascript.statuses." + status)

class ContactCentreReconciliationsModalInstCtrl extends ng.mmm.BaseCtrl
  constructor: ($scope, @$uibModalInstance, reference, @reconciliations,
  @status) ->
    $scope.$on "#{reference} reconciliations", @bind @setReconciliations
    $scope.$on "#{reference} status", @bind @setStatus
    $scope.close = @bind @close

  setReconciliations: (event, reconciliations) ->
    @reconciliations = reconciliations

  setStatus: (event, status) ->
    @status = status

  close: ->
    @$uibModalInstance.close this

class ContactCentreReconciliationsModalCtrl extends ng.mmm.BaseCtrl
  constructor: (@$scope, @$uibModal) ->
    @$scope.displayReconciliations = @bind @displayReconciliations
    @$scope.edit = @bind @edit
  edit: (path) ->
    scope = @$scope
    modalInst = @$uibModal.open
      templateUrl: path
      controller: [
        '$scope', '$uibModalInstance', 'reference', 'safety_report_version'
        'reconciliations', 'status', ContactCentreReconciliationsModalInstCtrl
      ],
      keyboard: false    # no ESC, so no rejected promise
      backdrop: 'static' # no rejected promise when clicking outside of the modal
      resolve:
        reference: -> scope.reference
        safety_report_version: -> scope.safety_report_version
        reconciliations: -> scope.reconciliations
        status: -> scope.status
    modalInst.result.then @bind @update

  update: (modalInst) ->
    scope = @$scope
    status = modalInst.status
    status = I18n.t("javascript.statuses." + status)
    scope.status = status
    scope.reconciliations = modalInst.reconciliations

ng
  .module('mmm.contact-centre-reconciliations', [
    'ngResource', 'ui.bootstrap.modal', 'mmm.modal'
  ])
  .factory('Reconciliation', ['$resource', reconciliationFactory])
  .factory('ReconciliationHistory', ['$resource', reconciliationHistoryFactory])
  .factory('Report', ['$resource', 'Reconciliation', reportFactory])
  .factory('ReportHistory', ['$resource', 'ReconciliationHistory', reportHistoryFactory])
  .factory('System', ['$resource', systemFactory])
  .controller('MmmContactCentreReconciliations', [
    'Report', 'Reconciliation', 'ReportHistory', 'ReconciliationHistory', 'System', 'confirmFilter', '$scope'
    '$rootElement', ContactCentreReconciliationsCtrl
  ])
  .controller('MmmContactCentreReconciliationsModal', [
    '$scope', '$uibModal', ContactCentreReconciliationsModalCtrl
  ])
