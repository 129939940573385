require("./base-controller");

ng = angular

class ReportDataSelect extends ng.mmm.BaseCtrl
  constructor: (@scope, @http) ->
    @init()

  init: ->
    @scope.changeSite = (site) =>
      @scope.journeys = @journeysForSite(site)
      @scope.showJourneys = @showJourneySelector(site)
      @scope.showCountries = @showCountrySelector(site)
      @scope.country = @scope.defaultCountry if @scope.showCountries && @scope.defaultCountry in @scope.countries
      @getLanguagesValues(@defaultSiteCountry(site).country || @scope.country)
    @scope.changeCountry = (country) =>
      @scope.language = null
      @getLanguagesValues(country)
    @scope.preselectSite = (site) =>
      if site
        @scope.changeSite site
        @scope.site = site

  getLanguagesValues: (country) ->
    unless country?.length
      @scope.showLanguages = false
      return
    @http.get(@scope.listPath, { params: { country: country } }).then (response) =>
      @scope.languages = @getLanguages(response.data)
      @scope.language = @scope.languages[0].key if @scope.languages.length == 1
      @scope.showLanguages = @showLanguageSelector(Object.keys(response.data))

  getLanguages: (data) ->
    jQuery.map Object.keys(data), (key, i) ->
      key: key, label: data[key]

  defaultSiteCountry: (site) ->
    $.grep(@scope.sites, (e) ->
      e.id == site
    )[0]

  journeysForSite: (site) ->
    site_data = $.grep(@scope.sites, (e) ->
      e.id == site
    )[0]
    site_data.journeys

  showJourneySelector: (site) ->
    @journeysForSite(site).length > 1

  showCountrySelector: (site) ->
    !@defaultSiteCountry(site).country

  showLanguageSelector: (languages) ->
    languages.length && jQuery.inArray(@scope.defaultLanguage, languages) == -1

ng
  .module('mmm.report-data-select', [])
  .controller('MmmReportDataSelect', ['$scope', '$http', ReportDataSelect])
