(function(I18n){
  /**
  * Features:
  * - when admin role selected should not display the site dropdown
  * - sites are unique per role
  * - only one role type can be used at the time for every roles
  */
  function UserAdminController($http) {
    this.state = initialState();
    var self = this;

    function initialState(){
      return {
        usedSites: [],
        availableSites: [],
        dropdownOptions : {
          roleTypes: [],
          roles: [],
          sites: []
        },
        current: {
          roleType: {},
          role: {},
          site: {}
        },
        lastSelectedRoleType: {},
        selectedRoles: [],
        exclusiveRoles: [],
        adminSelected: false,
        loadSelectedRoles: true,
        showFields: true
      }
    }

    function getUserRoles(){
      var userId = $('#user_id').val();
      if(userId) {
        $http.get('/admin/users/new/ajax_get_user_roles', {
          params: {id: userId}
        }).then(function(response){
          if(self.state.loadSelectedRoles) {
            self.state.selectedRoles = response.data;
            updateUsedSites(response.data);
            updateLastSelectedRoleType(response.data);
          }
          getRoleTypes();
          changeIsAdminState();
          changeShowFields();
        });
      } else {
        getRoleTypes();
        changeIsAdminState();
        changeShowFields();
      }
    }

    getUserRoles();

    function getExclusiveRoles(){
      $http.get('/admin/users/new/ajax_get_exclusive_user_roles').then(function(response){
        self.state.exclusiveRoles = response.data;
      });
    }

    getExclusiveRoles();

    function updateUsedSites(userRoles) {
      var usedSites = [];
      angular.forEach(userRoles, function(userRole) {
        if (userRole.site && usedSites.indexOf(userRole.site) == -1) {
            usedSites.push(parseInt(userRole.site.value));
        }
      });
      self.state.usedSites = usedSites;
    }

    function updateLastSelectedRoleType(userRoles) {
      // get the first role type (they should all be equal) when role type is site user(End user)
      if(userRoles.length) {
        self.state.lastSelectedRoleType = {value: userRoles[0].roleType.value, label: I18n.t("javascript.roles." + userRoles[0].roleType.value)};
      }
    }

    function getRoleTypes() {
      $http.get('/admin/users/new/ajax_get_role_types').then(function(response){
        if(isEmptyObject(self.state.lastSelectedRoleType)) {
          self.state.dropdownOptions.roleTypes = [self.state.lastSelectedRoleType];
        } else {
          self.state.dropdownOptions.roleTypes = response.data;
        }
      });
    }

    this.getRoles = function(){
      $http.get('/admin/users/new/ajax_get_roles', {
        params: {role_type: self.state.current.roleType.value}
      }).then(function(response){
        if(isExclusiveRoleSelected()) {
          self.state.dropdownOptions.roles = [self.state.selectedRoles[self.state.selectedRoles.length - 1].role];
        } else {
          self.state.dropdownOptions.roles = response.data;
        }
        changeIsAdminState();
        self.state.current.role = {}; // Reset current role when role type is changed
      });
    }

    this.getSites = function(){
      $http.get('/admin/users/new/ajax_get_sites', {
          params: {role: self.state.current.role.value}
      }).then(function(response){
        self.state.dropdownOptions.sites = response.data;
        self.state.current.site = {}; // Reset current site when role is changed
      });
    }

    this.add = function(){
      // save a new reference otherwise it would affect our selectedRoles
      var cloneCurrent = angular.copy(self.state.current);
      self.state.selectedRoles.push(cloneCurrent);
      self.state.usedSites.push(self.state.current.site.value);
      self.state.lastSelectedRoleType = self.state.current.roleType;
      resetCurrent();
      resetDropdownOptions();
      getRoleTypes();
      changeIsAdminState();
      changeShowFields();
    }

    this.remove = function(index){

      self.state.selectedRoles = self.state.selectedRoles.filter(function(value, key){
        return key != index
      });

      self.state.usedSites = self.state.usedSites.filter(function(value){
        return self.state.selectedRoles.some(function(obj){
          return obj.site.value == value;
        });
      })

      self.state.dropdownOptions.sites = self.state.dropdownOptions.sites.filter(function(value){
          return self.state.usedSites.indexOf(value.value) == -1
        });

      if (!self.state.selectedRoles.length) {
        self.state.lastSelectedRoleType = {};
      }
      getRoleTypes();
      changeIsAdminState();
      changeShowFields();
    }

    this.availableSites = function(){
      return self.state.dropdownOptions.sites.filter(function(obj){
        return self.state.usedSites.indexOf(obj.value) == -1
      });
    }

    this.isEmptyCurrent = function() {
      return !(self.state.current.roleType.value && self.state.current.role.value && ( self.state.adminSelected || self.state.current.site.value ))
    }

    this.initialiseSelectedRoles = function(userRoles) {
      if(userRoles) {
        self.state.loadSelectedRoles = false;
        self.state.selectedRoles = userRoles;
        updateUsedSites(userRoles);
        updateLastSelectedRoleType(userRoles);
        getRoleTypes();
        changeIsAdminState();
        changeShowFields();
      }
    }

    this.showSiteDropdown = function() {
      return (!self.state.adminSelected && isEmptyObject(self.state.current.role));
    }

    this.showSelectedRole = function(role, locale) {
      if(role.site && role.site.value) {
        return I18n.t("javascript.roles." + role.roleType.value, {locale: locale}) + " (" + I18n.t("javascript.roles." + role.role.value, {locale: locale}) + ", " + role.site.label + ")"
      }else{
        return I18n.t("javascript.roles." + role.roleType.value, {locale: locale}) + " (" + I18n.t("javascript.roles." + role.role.value, {locale: locale}) + ")"
      }
    }

    function changeIsAdminState(){
      self.state.adminSelected = (self.state.lastSelectedRoleType.value == "admin_role" || self.state.current.roleType.value == "admin_role");
    }

    function isExclusiveRoleSelected(){
      var selectedExclusiveRoles = [];

      if(self.state.selectedRoles.length) {
        selectedExclusiveRoles = self.state.selectedRoles.filter(function(obj){
          return self.state.exclusiveRoles.indexOf(obj.role.value) != -1
        });
      }
      return !!selectedExclusiveRoles.length
    }

    function isEmptyObject(obj) {
      return Object.keys(obj).length
    }

    function resetCurrent() {
      self.state.current = {
        site: {},
        role: {},
        roleType: {}
      }
    }

    function resetDropdownOptions() {
      self.state.dropdownOptions = {
          roleTypes: [],
          roles: [],
          sites: []
      }
    }

    function changeShowFields() {
      if(self.state.adminSelected) {
        self.state.showFields = false;
      } else {
        self.state.showFields = true;
      }
    }
  }

  angular.module('mmm.userAdmin', [])
    .controller('UserAdminController', ['$http', UserAdminController])

}(window.I18n))
