unauthorized = ($window, $q) ->
  respond = (response) ->
    if response.headers('Reportum-Session') is 'create' or response.status is 401
      $window.location = '/users/sign_in'
    else if 199 < response.status < 400
      response
    else
      $q.reject response
  response: respond
  responseError: respond

errorHandler = ($rootScope, $q) ->
  responseError: (rejection) ->
    msg = switch
      when rejection.status in [0, 408]
        'There was a network error. Please try again.'
      when 499 < rejection.status < 600
        'There was a problem processing your request. Please try again.'
    if msg
      $rootScope.$broadcast 'alert',
        type: 'danger'
        msg: msg
    $q.reject rejection

provider = ($provide) ->
  $provide.factory 'unauthorizedHttpInterceptor', ['$window', '$q', unauthorized]
  $provide.factory 'errorHttpInterceptor', ['$rootScope', '$q', errorHandler]

angular
  .module('mmm.response-handlers', ['ui.bootstrap.alert'])
  .config(['$provide', provider])
