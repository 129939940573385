require('./../angular/angular-cookie')

addTimezoneOffsetToCookie = (ipCookie)->
  # We change the sign to negative because of
  # http://www.w3schools.com/jsref/jsref_gettimezoneoffset.asp
  # For example, If your time zone is GMT+2, -120 will be returned.
  ipCookie 'timezoneOffset', -new Date().getTimezoneOffset(), path: '/'

angular
  .module 'mmm.timezone', ['ipCookie']
  .run ['ipCookie', addTimezoneOffsetToCookie]
