require('angular');
require('angular-resource');
require('angular-ui-bootstrap');
require('angular-utils-pagination');
require('angular-animate');
angularBusyTpl = require('./../angular/angular-busy-tpls');
require('@cgross/angular-busy');
require("./action-list");
require("./alerts");
require("./auto-complete");
require("./auto-resize");
require("./caret");
require("./collapse");
require("./contact-centre-quality-review");
require("./contact-centre-reconciliations");
require("./countdown");
require("./country-priority-select");
require("./external-submission-polyfill");
require("./language-selector");
require("./lms");
require("./loading");
require("./modal");
require("./multiple-select");
require("./navigation");
require("./report-data-select.coffee");
require("./report_listings");
require("./response-handlers");
require("./same-as");
require("./session");
require("./sites");
require("./orderTable");
require("./studies");
require("./timezone");
require("./typeahead");
require("./user_admin");
require("./users");
require("./select-all");
require("./services");
require("./symposium-config");
require("./button");

registerHttpInterceptors = ($httpProvider) ->
  $httpProvider.interceptors.push 'unauthorizedHttpInterceptor', 'errorHttpInterceptor'

NoopCtrl = ($scope) ->

angular
  .module('mmm', ng_modules)
  .value('cgBusyDefaults',
    templateUrl: angularBusyTpl
  )
  .config(['$httpProvider', registerHttpInterceptors])
  .controller('NoopCtrl', ['$scope', NoopCtrl])
