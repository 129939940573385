// Holds the data and perform all CRUD
(function(I18n) {
  function StudiesCtrl(filters, $q) {
    var self = this;
    this.data = {};
    this.loading = false;
    this.filters = initialFilters();
    this.bufferedFilters = initialBufferedFilters();

    function initialFilters(){
      return { page:1 }
    }

    function initialBufferedFilters(){
      return {};
    }

    function getStudies() {
      self.loading = true;
      filters.get(filters.STUDY_LIST, self.filters)
        .then(function (data) {
          self.loading = false;
          self.data.studies = data.list;
          self.data.perPage = data.per_page;
          self.data.total = data.total;
        });
    }

    getStudies();

    this.pageChanged = function(newPage) {
      this.filters.page = newPage;
      getStudies();
    }

    this.reset =  function() {
      self.bufferedFilters = initialBufferedFilters();
      self.filters = initialFilters();
      getStudies();
    }

    this.runFilter = function(){
      this.filters.page = 1;
      this.filters = $.extend(this.filters, this.bufferedFilters, {});
      getStudies();
    }

    function toggleAFilter(key, value) {
      if (!angular.isArray(this.bufferedFilters[key])) {
        this.bufferedFilters[key] = []
      }
      // remove
      if (this.bufferedFilters[key].indexOf(value) > -1) {
        this.bufferedFilters[key].splice(this.bufferedFilters[key].indexOf(value), 1)
          // add
      } else {
        this.bufferedFilters[key].push(value)
      }
    }

    this.toggleStatus = toggleAFilter.bind(this, 'status');
  }

  function ListCtrl($rootScope) {
    this.selectedRow = null;

    $rootScope.$on("studies.filtersservice.filtered", checkSelectedStillInView.bind(this));

    function checkSelectedStillInView(_e, payload) {
      if(!this.rowSelected) {
        return;
      }

      var isIn = payload.list.some(function(obj){
        return obj.study.id == this.rowSelected.study.id;
      }.bind(this));

      if(!isIn) {
        this.rowSelected = null;
      }

    }

    function selectRow(row, e) {
      var rowEl = angular.element(e.currentTarget);
      if (rowEl.hasClass('selected')) {
        this.rowSelected = null;
      } else {
        this.rowSelected = row;
      }
    }

    this.selectRow = selectRow;

    this.reset = function (){
      this.rowSelected = null;
    }

  }

  function FiltersService($http, $rootScope, objectToQueryString) {
    this.STUDY_LIST = '/admin/study/list';

    this.get = function (type, params) {
      if (params) {
        type = type + '?' + objectToQueryString(params)
      }
      return $http({
        method: 'GET',
        url: type
      }).then(function (response) {
        $rootScope.$emit("studies.filtersservice.filtered", response.data);

        return response.data;
      });
    }
  }

  function objectToQueryStringService() {
    function buildParams(prefix, obj, add) {
      var name, i, l, rbracket;
      rbracket = /\[\]$/;
      if (obj instanceof Array) {
        for (i = 0, l = obj.length; i < l; i++) {
          if (rbracket.test(prefix)) {
            add(prefix, obj[i]);
          } else {
            buildParams(prefix + "[" + ( typeof obj[i] === "object" ? i : "" ) + "]", obj[i], add);
          }
        }
      } else if (typeof obj == "object") {
        // Serialize object item.
        for (name in obj) {
          buildParams(prefix + "[" + name + "]", obj[name], add);
        }
      } else {
        // Serialize scalar item.
        add(prefix, obj);
      }
    }

    return function buildQueryString(obj) {
      var prefix, s, add, name, r20, output;
      s = [];
      r20 = /%20/g;
      add = function (key, value) {
        // If value is a function, invoke it and return its value
        value = ( typeof value == 'function' ) ? value() : ( value == null ? "" : value );
        s[s.length] = encodeURIComponent(key) + "=" + encodeURIComponent(value);
      };
      if (obj instanceof Array) {
        for (name in obj) {
          add(name, obj[name]);
        }
      } else {
        for (prefix in obj) {
          if (obj[prefix] === null || obj[prefix] === undefined || obj[prefix] === "") {
            continue;
          }
          buildParams(prefix, obj[prefix], add);
        }
      }
      output = s.join("&").replace(r20, "+");
      return output;
    }
  }

  /**
   * When submitting a form lock it until finished processing, also displays a nice spinner on the submit button
   */
  function formProcessing() {
    return {
      priority: '1001', // compile first
      restrict: 'A',
      link: function (scope, el, attrs) {
        el.bind('submit', function (e) {
          if (el.hasClass('submitting')) {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }
          el.addClass('submitting');
        })

      }
    }
  }

  function paginationDetails(countItemsOnXPage, $sce) {
    return {
      restrict: 'EA',
      template: '<span ng-bind-html="text()"></span>',
      replace: true,
      scope: {
        perPage: "=",
        page: "=",
        total: "="
      },
      link: function (scope, el, attrs) {

          scope.text = function () {
              if (scope.total == 0) {
                  return ""
              } else if (scope.total == 1) {
                  return $sce.trustAsHtml(I18n.t('javascript.displaying_one_result'))
              } else

                  return $sce.trustAsHtml(I18n.t('javascript.displaying_results', {
                      from: scope.from(),
                      to: scope.to(),
                      total: scope.total
                  }))
          };

        // make sure this is reevaluated when page change
        scope.from = function () {
          if(scope.total == 0) {
            return 0;
          }
          return ((scope.page - 1) * scope.perPage) + 1;
        };
        scope.to = function () {
          return ((scope.page - 1) * scope.perPage) + countItemsOnXPage(scope.page, scope.perPage, scope.total) ;
        }
      }
    }
  }

  function paginationCounterService(){
    return function(page, perPage, total){
      if(page * perPage <= total){
        return perPage;
      }
      else{
        return total - (page - 1) * perPage
      }
    }
  }

  angular.module('mmm.studies', [])
    .controller('MMMStudies', ['StudyFilters', '$q', StudiesCtrl])
    .controller('MMMList', ['$rootScope', ListCtrl])
    .factory('objectToQueryString', [objectToQueryStringService])
    .service('StudyFilters', ['$http', '$rootScope', 'objectToQueryString', FiltersService])
    .factory('paginationCounter', [paginationCounterService])
    .directive('mmmFormProcessing', [formProcessing])
    .directive('mmmPaginationDetails', ['paginationCounter', '$sce', paginationDetails]);
}(window.I18n))
