countdown = ($rootScope, $interval) ->
  restrict: 'A'
  replace: yes
  template: '<span ng-bind="count"></span>'
  scope:
    start: '='
    onFinish: '='
  link: (scope, element, attrs) ->
    onFinish = scope.onFinish or ->
    tick = ->
      scope.count--
      onFinish() if scope.count is 0
    scope.reset = ->
      scope.count = scope.start
      $interval.cancel(stop) if stop

    scope.reset()

    # stop the last 1 min counter and close modal
    stopCounter = (e) ->
        if e.key == 'refreshTimeout'
          $rootScope.$broadcast 'mmm.session.resetFromCounter'

    window.addEventListener('storage', stopCounter, false)

    stop = $interval tick, 1000, scope.count
    $rootScope.$on 'mmm.session.reset', scope.reset

angular
  .module 'mmm.countdown', []
  .directive 'mmmCountdown', ['$rootScope', '$interval', countdown]
