// Holds the data and perform all CRUD
(function(I18n) {
  function ReportListingsCtrl(filters, $q, $timeout) {
    var self = this;
    this.data = {};
    this.loading = false;
    this.filters = initialFilters();
    this.bufferedFilters = initialBufferedFilters();
    loadSavedFilters();

    function initialFilters(){
      return { page:1 }
    }

    function initialBufferedFilters(){
      return { "date_from": null, "date_to": null };
    }

    function loadSavedFilters() {
      var sFilters = localStorage.getItem("contact_centre_report_listing_filters");
      if (sFilters) {
        try {
          var localStorageFilters = JSON.parse(sFilters)
          if (localStorageFilters) {
            self.filters = localStorageFilters;
            self.bufferedFilters = localStorageFilters;
            getReports();
          }
        } catch (e) {
        }
      }
    }

    function getReports() {
      self.loading = true;

      filters.get(filters.REPORT_LIST, self.filters)
        .then(function (data) {
          self.loading = false;
          self.data.reports = data.list;
          self.data.perPage = data.per_page;
          self.data.total = data.total;
        });
      var sFilters = JSON.stringify(self.filters);
      localStorage.setItem("contact_centre_report_listing_filters", sFilters);
    }

    function forceClearValidationOnReset(targetEl) {
      $(targetEl).removeClass("ng-invalid-date");
      $(targetEl).removeClass("ng-invalid");
      $(targetEl).addClass("ng-valid-date");
      $(targetEl).addClass("ng-valid");
    }

    getReports();

    this.pageChanged = function(newPage) {
      this.filters.page = newPage;
      getReports();
    }

    this.reset = function() {
      localStorage.setItem("contact_centre_report_listing_filters", null);
      self.bufferedFilters = initialBufferedFilters();
      self.filters = initialFilters();

      // Next force the datepicker to open on the current month after a reset
      // We do this by setting the date to today, then after angular applies
      // this we then clear the date value
      self.bufferedFilters.date_from = new Date();
      self.bufferedFilters.date_to = new Date();
      $timeout(function () {
        self.bufferedFilters.date_from = null;
        self.bufferedFilters.date_to = null;
      }, 1);
      forceClearValidationOnReset("#date_from");
      forceClearValidationOnReset("#date_to");

      $("#sites-selector option:selected").prop("selected", false)
      this.bufferedFilters["sites"] = [];

      getReports();
    }

    this.runFilter = function(){
      this.bufferedFilters.date_from = document.getElementsByName("date_range[from][date]")[0].value;
      this.bufferedFilters.date_to = document.getElementsByName("date_range[to][date]")[0].value;
      this.filters.page = 1;
      this.filters = $.extend(this.filters, this.bufferedFilters, {});
      getReports();
    }

    function toggleAFilter(key, value) {
      if (!angular.isArray(this.bufferedFilters[key])) {
        this.bufferedFilters[key] = []
      }
      // remove
      if (this.bufferedFilters[key].indexOf(value) > -1) {
        this.bufferedFilters[key].splice(this.bufferedFilters[key].indexOf(value), 1)
          // add
      } else {
        this.bufferedFilters[key].push(value)
      }
    }

    this.toggleSeriousness = toggleAFilter.bind(this, 'seriousness');
    this.toggleMyReports = function(){
      if(angular.isDefined(this.bufferedFilters.my_reports)){
        this.bufferedFilters.my_reports = !this.bufferedFilters.my_reports;
      }
      else{
        this.bufferedFilters.my_reports = true;
      }
    }

    this.onSiteListChange = function() {
      var values = $("#sites-selector").val();
      this.bufferedFilters["sites"] = values || [];
    }
  }

  function ListCtrl($rootScope) {
    this.REPORT_DETAILS = '/contact_centre/reporting/report_listings/';
    this.rowSelected = null;

    $rootScope.$on("report_listings.filtersservice.filtered", checkSelectedStillInView.bind(this));

    function checkSelectedStillInView(_e, payload) {
      if (!this.rowSelected) {
        return;
      }

      var isIn = payload.list.some(function (obj) {
        return obj.reference == this.rowSelected.reference;
      }.bind(this));

      if (!isIn) {
        this.rowSelected = null;
      }
    }

    function selectRow(row, e) {
      var rowEl = angular.element(e.currentTarget);
      if (rowEl.hasClass('selected')) {
        this.rowSelected = null;
      } else {
        this.rowSelected = row;
      }
    }

    this.selectRow = selectRow;

    this.reset = function (){
      this.rowSelected = null;
    };

    this.clickDetailsButton = function () {
      var detailsPage = this.REPORT_DETAILS + this.rowSelected.ref;
      window.location.href = detailsPage;
    }

  }

  function FiltersService($http, $rootScope, objectToQueryString) {
    this.REPORT_LIST = '/contact_centre/reporting/report_listings/list';

    this.get = function (type, params) {
      if (params) {
        type = type + '?' + objectToQueryString(params)
      }
      return $http({
        method: 'GET',
        url: type
      }).then(function (response) {
        $rootScope.$emit("report_listings.filtersservice.filtered", response.data);

        return response.data;
      });
    }
  }

  function objectToQueryStringService() {
    function buildParams(prefix, obj, add) {
      var name, i, l, rbracket;
      rbracket = /\[\]$/;
      if (obj instanceof Array) {
        for (i = 0, l = obj.length; i < l; i++) {
          if (rbracket.test(prefix)) {
            add(prefix, obj[i]);
          } else {
            buildParams(prefix + "[" + ( typeof obj[i] === "object" ? i : "" ) + "]", obj[i], add);
          }
        }
      } else if (typeof obj == "object") {
        // Serialize object item.
        for (name in obj) {
          buildParams(prefix + "[" + name + "]", obj[name], add);
        }
      } else {
        // Serialize scalar item.
        add(prefix, obj);
      }
    }

    return function buildQueryString(obj) {
      var prefix, s, add, name, r20, output;
      s = [];
      r20 = /%20/g;
      add = function (key, value) {
        // If value is a function, invoke it and return its value
        value = ( typeof value == 'function' ) ? value() : ( value == null ? "" : value );
        s[s.length] = encodeURIComponent(key) + "=" + encodeURIComponent(value);
      };
      if (obj instanceof Array) {
        for (name in obj) {
          add(name, obj[name]);
        }
      } else {
        for (prefix in obj) {
          if (obj[prefix] === null || obj[prefix] === undefined || obj[prefix] === "") {
            continue;
          }
          buildParams(prefix, obj[prefix], add);
        }
      }
      output = s.join("&").replace(r20, "+");
      return output;
    }
  }

  /**
   * When submitting a form lock it until finished processing, also displays a nice spinner on the submit button
   */
  function formProcessing() {
    return {
      priority: '1001', // compile first
      restrict: 'A',
      link: function (scope, el, attrs) {
        el.bind('submit', function (e) {
          if (el.hasClass('submitting')) {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }
          el.addClass('submitting');
        })

      }
    }
  }

  function paginationDetails(countItemsOnXPage, $sce) {
    return {
      restrict: 'EA',
      template: '<span ng-bind-html="text()"></span>',
      replace: true,
      scope: {
        perPage: "=",
        page: "=",
        total: "="
      },
      link: function (scope, el, attrs) {

          scope.text = function () {
              if (scope.total == 0) {
                  return ""
              } else if (scope.total == 1) {
                  return $sce.trustAsHtml(I18n.t('javascript.displaying_one_result'))
              } else

                  return $sce.trustAsHtml(I18n.t('javascript.displaying_results', {
                      from: scope.from(),
                      to: scope.to(),
                      total: scope.total
                  }))
          };

          // make sure this is reevaluated when page change
        scope.from = function () {
          if(scope.total == 0) {
            return 0;
          }
          return ((scope.page - 1) * scope.perPage) + 1;
        };
        scope.to = function () {
          return ((scope.page - 1) * scope.perPage) + countItemsOnXPage(scope.page, scope.perPage, scope.total) ;
        }
      }
    }
  }

  function paginationCounterService(){
    return function(page, perPage, total){
      if(page * perPage <= total){
        return perPage;
      }
      else{
        return total - (page - 1) * perPage
      }
    }
  }

  angular.module('mmm.reportListings', [])
    .controller('MMMReportListings', ['ReportListingFilters', '$q', '$timeout', ReportListingsCtrl])
    .controller('MMMList', ['$rootScope', ListCtrl])
    .factory('objectToQueryString', [objectToQueryStringService])
    .service('ReportListingFilters', ['$http', '$rootScope', 'objectToQueryString', FiltersService])
    .factory('paginationCounter', [paginationCounterService])
    .directive('mmmFormProcessing', [formProcessing])
    .directive('mmmReportListingPaginationDetails', ['paginationCounter', '$sce', paginationDetails]);
}(window.I18n))

// Catch ajax response from admin report listing email request form
// in order to update email button state and display validation message
$(document).ajaxComplete(function(event, data, status, xhr) {
  if($('[data-ng-controller="MmmDownload"]')) {
    var response = data.responseJSON;
    angular.element($('[data-ng-controller="MmmDownload"]'))
      .scope().$apply(function (scope){
        scope.downloadDisabled = false
      });
    var response = data.responseJSON;
    if(response && response.email_vaildation_message) {
      alert(response.email_vaildation_message);
      angular.element($('[data-ng-controller="MmmDownload"]'))
        .scope().$apply(function (scope){
          scope.emailDisabled = false
        });
    }
  }
});
