'use strict';
/* global JS_AIRBRAKE_KEY, JS_AIRBRAKE_ID */
require('./../admin/stylesheets/style');
require('./../elm/style.scss');
require('logo.png');
require('logo2.png');
require('logo_pdf.png');

require('android-icon-192x192.png');
require('apple-icon-114x114.png');
require('apple-icon-120x120.png');
require('apple-icon-144x144.png');
require('apple-icon-152x152.png');
require('apple-icon-180x180.png');
require('apple-icon-57x57.png');
require('apple-icon-60x60.png');
require('apple-icon-72x72.png');
require('apple-icon-76x76.png');
require('favicon-16x16.png');
require('favicon-32x32.png');
require('favicon-96x96.png');
require('favicon.ico');
require('ms-icon-144x144.png');

require('jquery');
require('i18n-js');
require('jquery-colorbox');
require('bootstrap-sass');

require('./../../fontawesome-pro/js/fontawesome.min');
require('./../../fontawesome-pro/js/brands.min');
require('./../../fontawesome-pro/js/regular.min');
require('./../../fontawesome-pro/js/solid.min');
require('./../../fontawesome-pro/js/v4-shims.min');

// NOTE: This line have to be the last. To ensure that it's "require" AFTER jQuery itself.
require('jquery-ujs');
const AirbrakeClient = require('airbrake-js');

$(document)
  .ready(function () {
    const airbrake = new AirbrakeClient({
      projectId: JS_AIRBRAKE_ID,
      projectKey: JS_AIRBRAKE_KEY
    });
    airbrake.call(boot);
  });

function boot() {
  require('./../admin');
  require('./../application/journey/modal');
  require('./../application/footer-links');

  // angular and such
  require('./../admin/mmm/mmm');
}
