ADMIN_URL = '/admin'
LOGOUT_URL = '/users/sign_out?locale=en'

sessionTimeoutNotifier = ($timeout, $window, $http, openModal) ->
  link = (scope, element, attrs) ->
    stop = undefined; # make sure coffee create a new local var
    # due to JS limitation the setTimemout max date is 24 days
    milliseconds = +attrs.mmmSessionTimeoutNotifier
    timeout = +attrs.mmmSessionTimeout / 1000;
    # storage event isn't fired if the value hasn't changed, add a random value to resolve that
    localStorage.setItem 'refreshTimeout', Math.random()

    scope.$on 'mmm.session.reset', -> localStorage.setItem 'refreshTimeout', Math.random()

    notify = ->
      openModal()
        .then(
          -> $http.get ADMIN_URL
        )
        .then startTimeout

    # stop the main counter and start a new one
    resetCounter = (e) ->
      if e.key == 'refreshTimeout'
        $timeout.cancel(stop) if typeof stop != 'undefined'

    # 2000ms is A Magic number. I tried with 50ms - it's not enough for IE11. 2000ms works fine.
    setTimeout(=>
      window.addEventListener('storage', resetCounter)
    2000)

    (startTimeout = -> stop =  $timeout notify, milliseconds)()

  restrict: 'A'
  link: link

angular
.module 'mmm.session', ['mmm.modal']
.directive 'mmmSessionTimeoutNotifier', [
  '$timeout', '$window', '$http', 'sessionTimeoutNotifyFilter'
  sessionTimeoutNotifier
]
