'use strict';

(function () {

  let modalInstance = null;
  let siteId = null;
  let forwardSites = [];
  let selectedRow = {};

  function orderTable($rootScope, humanize, $http, $uibModal, $timeout) {
    const self = this;
    this.rows = [];
    this.humanize = humanize;
    this.disabled = false;
    this.move = function (from, to) {
      if (siteId) {
        const backUpRows = angular.copy(self.rows);
        backUpRows.splice(from, 0, backUpRows.splice(to, 1)[0]);
        const rulesIds = backUpRows.map(row => row.id);

        self.disabled = true;
        $http.post(`/admin/configuration/sites/${siteId}/site_rules/update_rules_order`,
          {
            rulesIds: rulesIds
          })
          .then(() => self.getRules())
          .finally(() => self.disabled = false);
      } else {
        return 'Failed to move due to missing site_id';
      }
    };

    this.openModal = function (url) {

      modalInstance = $uibModal.open({
        templateUrl: url,
        controller: 'MmmForwardToModal as fm'
      });
    };

    this.setSiteId = function (id) {
      siteId = id;
    };

    this.setForwardSites = function (sites) {
      forwardSites = sites;
    };

    this.setSelectedRow = function (row) {
      selectedRow = row;
    };

    this.getRules = function(){
      $http.get(`/admin/configuration/sites/${siteId}/site_rules`)
        .then(function (result) {
          self.rows = result.data;
        });
    };

    $timeout(self.getRules, 0);

    $rootScope.$on('update_rules' , function(event, data) {
      self.rows = data;
    });

    this.delete = function (path) {
      $http.delete(path)
        .then(() => self.getRules());
    };

    this.formatCountries = function (countries) {
      return countries.map(country => country.label).join(", ");
    };
    return false;
  }

  function forwardModel($scope, $http, $sce) {
    const self = this;
    this.siteId = siteId;
    this.forwardSites = forwardSites;
    this.selectedRow = selectedRow;
    this.selectedCountries = selectedRow.criteria;
    this.alerts = [];

    this.getCountries = function () {
      if (self.edit) {
        return JSON.stringify(this.selectedCountries.map(country => country.key));
      } else {
        return JSON.stringify([]);
      }
    };

    this.submit = function (countries, ruleId) {
      var params = {
        countries: countries.map(country => country.key),
        rule:  { destination_site_id: this.selectedSite }
      };
      if (!this.edit) {
        $http.post(`/admin/configuration/sites/${this.siteId}/site_rules`, params)
          .then(function(response){
            self.updateRules(response);
          }); } else {
        $http.put(`/admin/configuration/sites/${this.siteId}/site_rules/${ruleId}`, params)
          .then(function(response){
            self.updateRules(response);
          });
      }
    };

    this.updateRules = function (response) {
      if (response.data.ok === undefined) {
        this.alerts = [{type: 'danger', msg: $sce.trustAsHtml(response.data.error)}];
      } else {
        $scope.$emit('update_rules', (response.data.ok));
        self.close();
      }
    };
    this.close = function (){
      modalInstance.close();
    };

    this.submitEnabled = function(enabled) {
      return !this.selectedSite.length || !enabled.length;
    };
  }

  angular.module('mmm.orderTable', ['mmm.services', 'ui.bootstrap', 'mmm.navigation', 'ui.bootstrap.alert'])
    .controller('mmmOrderTable', ['$rootScope', 'humanize', '$http', '$uibModal', '$timeout', orderTable])
    .controller('MmmForwardToModal', ['$scope', '$http', '$sce', forwardModel]);
}());
