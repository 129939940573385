I18n = window.I18n

ContactCentreQualityReviewCtrl = ($scope, $http) ->
  $scope.isCollapsed = true
  $scope.toggleLabel = ' ' + I18n.t('javascript.filter')

  # Handler for when user clicks the 'filter' button to show/hide the controls
  $scope.handleToggle = ->
    $scope.isCollapsed = !$scope.isCollapsed
    $scope.toggleLabel = if $scope.isCollapsed then ' ' + I18n.t('javascript.filter') else ' ' + I18n.t('javascript.hide')

angular
  .module('mmm.contact-centre-quality-review', [])
  .controller('MmmContactCentreQualityReviewCtrl', ['$scope', '$http', ContactCentreQualityReviewCtrl])
