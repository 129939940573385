'use strict';

(function () {
  function disableButton () {
    this.disabled = false;
    this.triggerDisable = function () {
      this.disabled = true;
    };

    this.triggerKeyDisable = function (event) {
      if (event.keyCode == 13 & this.disabled) {
        event.preventDefault();
        return false;
      }
    };
  }

  angular.module('mmm.button', []).
    controller('mmmDisableButton', [disableButton]);
}());
