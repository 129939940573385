ng = angular

HEIGHT = 'height'
MIN_HEIGHT = 100

autoResize = (element)->
  $element = ng.element element
  currentHeight = $element.css 'height'
  currentHeight = if currentHeight then parseInt(currentHeight) else 0
  height = $element[0].scrollHeight
  height = MIN_HEIGHT if height < MIN_HEIGHT
  $element.css HEIGHT, "#{height}px" if currentHeight < height

handleAutoResize = ($event)->
  autoResize $event.target

handleBlur = ($event)->
  element = ng.element $event.target
  element.css HEIGHT, element.data 'original-height'

initAutoResize = (elem)->
  height = elem[0].offsetHeight + 'px'
  elem.data 'original-height', height
  elem.css HEIGHT, height
  autoResize elem

autoResizeLink = ($scope, elem, attrs)->
  elem.bind 'focus', handleAutoResize
  elem.bind 'keyup', handleAutoResize
  elem.bind 'blur', handleBlur
  initAutoResize elem

autoResizeDirectiveFactory = ->
  restrict: 'AC'
  link: autoResizeLink

ng
  .module 'mmm.auto-resize', []
  .directive 'mmmAutoResize', autoResizeDirectiveFactory

