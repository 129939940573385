ng = angular

setCaretAtEnd = ($elem)->
  elemLen = $elem.val().length
  elem = $elem[0]
  if document.selection
    # For IE Only
    # Use IE Ranges
    range = document.selection.createRange()
    # Reset position to 0 & then set at end
    range.moveStart 'character', -elemLen
    range.moveStart 'character', elemLen
    range.moveEnd 'character', 0
    range.select()
  else if elem.selectionStart is 0
    # Firefox/Chrome
    elem.selectionStart = elemLen
    elem.selectionEnd = elemLen

textareaDirective = (scope, element, attrs)->
  element
    .on 'mousedown', ->
      element.data 'clicking', true
    .on 'click', ->
      element.data 'clicking', false
    .on 'focus', ->
      unless element.data 'clicking'
        setTimeout ng.bind(null, setCaretAtEnd, element), 0

textareaFactory = ->
  restrict: 'E'
  link: textareaDirective

ng
  .module 'mmm.caret', []
  .directive 'textarea', textareaFactory

