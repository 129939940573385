ng = angular

class SameAsDirective
  constructor: (@controller, @scope, @attributes)->

  scopeProperty: (prop) ->
    props = prop.split '.'
    val = @scope
    val = val[p] for p in props
    val

  validate: (viewValue) ->
    if viewValue is @scopeProperty @attributes.sameAs
      @controller.$setValidity 'sameAs', on
      viewValue
    else
      @controller.$setValidity 'sameAs', off
      undefined

  @factory: ->
    {
      require: 'ngModel'
      link: SameAsDirective.link
    }

  @link: (scope, elm, attrs, ctrl) ->
    directive = new SameAsDirective ctrl, scope, attrs
    ctrl.$parsers.unshift ng.bind(directive, directive.validate)

ng
  .module('mmm.same-as', [])
  .directive('sameAs', SameAsDirective.factory)

