ng = angular

class TypeaheadCtrl
  constructor: (@$scope, @$http, @filterFilter) ->
    @$scope.getOptions = @bind @getOptions
    @$scope.handleSelect = @bind handleSelect
    @$scope.filterSelected = @bind @filterSelected
    @$scope.remove = @bind @remove
    @$scope.has = @bind @has
    @$scope.addNew = @bind @addNew
    @$scope.search = null
    @$scope.selected = []
    @$scope.selectedCountry = ""
    @$scope.loading = no
    @$scope.endpoint = ''
    @$scope.customEndpoint = null
    @$scope.$watch 'selected', @bind(handleSelectedChange), on
    @selectedLibraryProducts = []

  getOptions: ->
    if @$scope.customEndpoint
      @$scope.endpoint = ('/search/drugs?country=' + @$scope.selectedCountry)
    @$http
      .get("#{@$scope.endpoint}&q=#{encodeURIComponent(@$scope.search)}")
      .then(@bind(handleResponse), @bind(handleRequestError))

  filterSelected: (result) ->
    if @$scope.customEndpoint
      for d in @$scope.selected
        return if d.name == result.name && d.country == result.country
      result.name
    else
      result.name not in @selectedLibraryProducts

  has: (value) ->
    result in @$scope.selected

  addNew: ->
    if @$scope.customEndpoint
      item = new:yes, name:@$scope.newProduct, country:@$scope.selectedCountry
    else
      item = new:yes, name:@$scope.newProduct

    if @$scope.selected.length == 0 || @$scope.selected.findIndex((el) => isSameObject(item, el)) < 0
      @$scope.selected.push item

    @$scope.newProduct = null

  remove: (value) ->
    index = @$scope.selected.indexOf value
    @$scope.selected.splice index, 1 if index >= 0

  bind: (method) ->
    method = @[method] if typeof method is 'string'
    ng.bind this, method

  handleSelectedChange = (newValue, oldValue) ->
    if newValue?
      @selectedLibraryProducts = []
      for selected in newValue
        @selectedLibraryProducts.push selected.name unless selected.new

  ciEquals = (value, other) ->
    if typeof value == "string" and typeof other == "string"
      value.localeCompare(other, undefined, { sensitivity: "accent" }) == 0
    else
      value == other

  removeExtraKeys = (list) ->
    # These keys are not needed during object comparison. In most cases they
    # produce false negative results.
    extraKeys = ["$$hashKey", "new"]
    list.filter((e) -> !extraKeys.includes(e))

  isSameObject = (obj, other) ->
    objKeys = removeExtraKeys(Object.keys(obj))
    otherKeys = removeExtraKeys(Object.keys(other))

    if objKeys.length != otherKeys.length
      return false

    for key in objKeys
      if !ciEquals(obj[key], other[key])
        return false

    true

  handleSelect = (item) ->
    item.country = @$scope.selectedCountry if @$scope.customEndpoint
    item.new = no

    if @$scope.selected.length == 0 || @$scope.selected.findIndex((el) => isSameObject(item, el)) < 0
      @$scope.selected.push item
    @$scope.search = null

  handleResponse = (response) ->
    @filterFilter response.data, @bind(@filterSelected)

  handleRequestError = (response) ->
    status = response.status
    if status
      switch
        when status > 499 && status < 599
          alert "There is a problem with the server at the moment. Please try again later."
        else
          alert "#{status}: You've taken too long. You'll need to restart your session."
    else if response.message
      alert "There was an error requesting from the server. #{response.message}"
    else
      alert "There was an error requesting from the server."

ng
  .module("mmm.typeahead", ["ui.bootstrap.typeahead"])
  .controller("MmmTypeahead", ["$scope", "$http", "filterFilter", TypeaheadCtrl])
