'use strict';

(function () {
  angular.module('mmm.services', [])
    .factory('humanize', function () {
      return function (input) {
        return input.split('_')
          .map(input =>
            input.charAt(0)
              .toUpperCase() + input.slice(1))
          .join(' ');
      };
    });
}());
