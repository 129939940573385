'use strict';

(function ($, I18n) {
  const addSuccessStyle = function (el) {
    $(el)
      .removeClass('text-warning')
      .addClass('text-success');
  };

  let addErrorStyle = function (el) {
    $(el)
      .addClass('text-warning')
      .removeClass('text-success');
  };

  function LmsStatus () {
    const COMPLETE = 'complete';
    const NEW_USER = 'new_user';
    const INCOMPLETE = 'incomplete_before_due';
    const OVERDUE = 'incomplete_over_due';

    function formatDate (date, timezone) {
      let newDate = new Date(date);
      newDate = new Date(newDate.valueOf() + newDate.getTimezoneOffset() *
        60000);

      const day = padLeft(newDate.getDate(), 2, '0');
      const month = monthLocale(newDate.getMonth());
      const year = newDate.getFullYear().toString()
        .substr(-2);
      const hours = padLeft(newDate.getHours(), 2, '0');
      const minutes = padLeft(newDate.getMinutes(), 2, '0');
      const formattedDate = `${day} ${month} ${year}`;
      const formattedTime = `${hours}:${minutes}`;

      const formatted = `${formattedDate}, ${formattedTime} ${timezone}`;

      return I18n.t('javascript.lms_statuses.last_updated', {date: formatted});
    }

    function monthLocale (monthIndex) {
      const monthObj = I18n.t('javascript.calendar.months.short');
      const monthMaps = [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec'
      ]
      return monthObj[monthMaps[monthIndex]];
    }

    function padLeft (a, n, str) {
      return Array(n - String(a).length + 1).join(str || '0') + a;
    }

    this.getStatus = (status) => {
      let statusLabel = '';
      switch (status) {
      case NEW_USER:
        statusLabel = I18n.t('javascript.lms_statuses.new');
        break;
      case OVERDUE:
        statusLabel = I18n.t('javascript.lms_statuses.overdue');
        break;
      case COMPLETE:
        statusLabel = I18n.t('javascript.lms_statuses.complete');
        break;
      case INCOMPLETE:
        statusLabel = I18n.t('javascript.lms_statuses.incomplete');
        break;
      default:
        statusLabel = I18n.t('javascript.lms_statuses.unknown');
      }
      return I18n.t('javascript.lms_statuses.training_status', {status: statusLabel});
    };

    this.getStatusAction = (status) => {
      switch (status) {
      case COMPLETE:
        return I18n.t('javascript.lms_statuses.go_to_training');
      default:
        return I18n.t('javascript.lms_statuses.train_now');
      }
    };

    this.execute = function () {
      window.location = this.loginLink;
    };

    this.updatedAt = function (date, tz) {
      if (tz == null) {
        tz = 'UTC';
      }
      return formatDate(date, tz);
    };

    this.isComplete = function (status) {
      return status === COMPLETE;
    };

    this.isIncomplete = function (status) {
      return status === INCOMPLETE;
    };

    this.isOverdue = function (status) {
      return status === OVERDUE;
    };

    this.isNew = function (status) {
      return status === NEW_USER;
    };
  }

  function LmsLearningGroup ($scope, $http) {
    const target = $('.connection-result');
    const errorMsg = 'Server Unreachable';

    this.checkAuth = function (url, username, password) {
      testLmsConnection({
        url: url,
        username: username,
        password: password
      })
        .then(updateConnectionStatusAlertBox)
        .catch(() => addErrorStyle(target.text(errorMsg)));
    };

    let updateConnectionStatusAlertBox = (payload) => {
      target.text(payload.data.response.msg || errorMsg);

      if (payload.data.response.success) {
        addSuccessStyle(target);
      } else {
        addErrorStyle(target);
      }
    };

    this.onGroupChange = function (role) {
      let groupIdSelector = `${role}_lms_group_id`;
      let groupId = $scope[groupIdSelector];
      if (groupId) {
        $scope[`${role}_lms_group_name`] = $(`#${groupIdSelector} option[value=${groupId}]`)[0].text;
      } else {
        $scope[`${role}_lms_role`] = '';
        $scope[`${role}_lms_group_name`] = '';
      }
    };

    this.onLmsEnable = function (isEnabled) {
      if (isEnabled) {
        testLmsConnection($scope.lg).then((result) => {
          if (result.data.response.success) {
            requestGroups();
          }
        }, ()=>{}
        );
      }
    };

    let testLmsConnection = (params) => $http.get('test_lms_connection', { params: params });

    let requestGroups = () => {
      $http
        .get('lms_groups')
        .then((result) => updateGroups(result.data.response),()=>{});
    };

    let updateGroups = (groups) => {
      $scope.groups = groups;
      if ($scope.lg.groupId) {
        const group = $.grep($scope.groups, e => e.id === Number($scope.lg.groupId))[0];
        $scope.group = {
          id: group.id,
          name: group.name
        };
      }
    };
  }

  angular.module('mmm.lms', []).
    controller('mmmLmsStatus', [LmsStatus]).
    controller('mmmLmsLearningGroup', ['$scope', '$http', LmsLearningGroup]);
}(jQuery, window.I18n));
