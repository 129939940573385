require("./base-controller");

ng = angular

class CollapseCtrl extends ng.mmm.BaseCtrl
  constructor: (@$scope, @$timeout, @$http) ->
    @$scope.isCollapsed = yes
    @$scope.collapse = @bind @collapse
    @$scope.open = @bind @open
    @$scope.toggle = @bind @toggle
    @$scope.toggleWithAjax = @bind @toggleWithAjax
    @$scope.formatTrigger = @bind @formatTrigger
    @$scope.product_details = []

  changeState: (state) ->
    @$timeout => @$scope.isCollapsed = state

  collapse: ->
    @changeState on

  open: ->
    @changeState off

  toggle: ->
    @changeState not @$scope.isCollapsed

  toggleWithAjax: (index, drug_name, country) ->
    @$scope.rows[index] = !@$scope.rows[index]
    if @$scope.product_details[index] then return
    @$http.get('/admin/configuration/load_product_details', {params: {drug_name, country}})
    .then((response) =>
      @$scope.product_details[index] = response.data
    )

ng
  .module('mmm.collapse', ['ngAnimate', 'ui.bootstrap.collapse'])
  .controller('MmmCollapse', ['$scope', '$timeout', '$http', CollapseCtrl])
